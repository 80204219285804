import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

import homeStyles from "./home.module.scss"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <h1>Hello there!</h1>
      <h2>
        I'm <span>James</span> , a full-stack developer.
      </h2>
      <p>
        Need a developer? <Link to="/contact">Contact me.</Link>
      </p>
    </Layout>
  )
}

export default IndexPage
